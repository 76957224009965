$theme-colors: (
  'primary': (
    'base': #D80E70,
    'light': #fff,
    'dark': #273677
  ),
  'accent': (
    'base': #f08110,
    'light': #ff8100,
    'dark': #e47f17
  ),
  'foreground': (
    'base': #393939,
    'light': #6e6e6e,
    'dark': #111
  ),
  'background': (
    'base': #E5E5E5,
    'light': #fff,
    'dark': #000
  )
);