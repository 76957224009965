
main{
  :global(.col){
    padding: 0;
  }
  hr{
    border-top: 1px solid #000;
  }
}
.wrapper{
  width: 100%;
  height: 100%;
  padding: 30px;
  padding-bottom: 100px;
}

.arrow-wrapper{
  width: 107px;
  height: 87px;
  img{
    width: 100%;
    
  }
}

:global(.parallax-inner){
  width: 100%;
  height: 100%;
  :global(.animated){
    width: 100%;
    height: 100%;
  }
}
.row-container{
  width: 100%;
}
  .text{
    position: absolute;
    z-index: 3;


    h1{
      text-align: left;
      font-family: Founders Grotesk;
      font-style: normal;
      font-weight: 500;
      font-size: 180px;
      color:#fff;
      margin: 0;
      line-height: 0.8;
      /* identical to box height, or 73% */

      text-transform: uppercase;
      @media(min-width: 1201px) and (max-width: 1359px){
        font-size: 155px;
      }
    }
  }

  .text-element{
    &:hover{
      text-decoration: none;
    }
  }

  .bigger-img{
    margin: auto;
    margin-top: 377px;
    width: 797px;
    height: 994px;
    .caption{
      display: none;
    }
  }
  .img-in-div{
    width: 100%;
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .hero-center-img{
    background-image: url("../assets/2.png");
  }

  .small-img{
    position: absolute;
    top: 85px;
    width: 316px;
    height: 400px;
    right: 0px;
    z-index: 2;
    .caption{
      margin-top: 11px;
      float:right;
      font-size: 27px;
      line-height: 34px;
      /* identical to box height, or 126% */

      text-transform: uppercase;

      color: #FFFFFF;

      mix-blend-mode: normal;
      opacity: 0.5;
    }
  }

  .hero-small-img{
    background-image: url("../assets/1.png");
  }
  
  .holder{
    width: 100%;
    max-width: 1400px !important;
    margin: auto;
  }


  .sec-row-text{
    position: absolute;
    right: 301px;
    top: 75px;
    max-width: 834px;
    .overlay-text{

      font-size: 60px;
      line-height: 55px;
      /* or 95% */

      text-transform: uppercase;

      color: #FFFFFF;
    }
    .caption{

      display: none;
      font-size: 18px;
      line-height: 21px;
      /* or 117% */

      text-transform: uppercase;

      color: #FFFFFF;
      margin-top: 15px;
    }
  }
  .sec-row-image{
    float: right;
    width: 545px;
    height: 565px;
    margin-top: 202px;
    .sec-img{
      width: 100%;
      height: 100%;
      @media(min-width: 1201px) and (max-width: 1359px){
        width: 80%;
        height: 80%;
      }
      background-image: url("../assets/3.png");
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
    }
    .caption{

      font-size: 18px;
      line-height: 21px;
      /* or 117% */

      text-transform: uppercase;
      mix-blend-mode: normal;
      opacity: 0.5;
      color: #FFFFFF;
      margin-top: 15px;
    }
  }

  .text-white {
    opacity: 1 !important;
  }
  

  .third-row-image{
    float: left;
    width: 535px;
    height: 542px;
    margin-top: -145px;
    .third-img{
      width: 100%;
      height: 100%;
      @media(min-width: 1201px) and (max-width: 1359px){
        width: 80%;
        height: 80%;
      }
      background-image: url("../assets/4.png");
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
    }
    .caption{

      font-size: 40px;
      line-height: 21px;
      max-width: 548px;
      /* or 117% */
      mix-blend-mode: normal;
      opacity: 0.5;
      text-transform: uppercase;

      line-height: 41px;
      color: #FFFFFF;
      margin-top: 15px;
    }
  }

.solid-line{
  width: 99%;
  height: 1px;  
  background: #FFFFFF;
  margin: auto;
  margin-top: 185px;
  margin-bottom: 85px;
}

.guess-work{
  width: 100%;
  min-height: 1143px;
  margin: auto;
  .jumbo{
    font-size: 120px;
    text-transform: uppercase;
    line-height: 80px;
    @media(min-width: 768px) and (max-width: 1359px){
      font-size: 80px;
      text-transform: uppercase;
      line-height: 55px;
    }
  }
  .offset-row{
    margin-bottom: 25px;
  }
  .desc{
    font-size: 24px;
    /* or 125% */
    max-width: 454px;

    color: #FFFFFF;
    opacity: 0.6;
    display: block;
    float: right;
    margin-top: 33px;
    line-height: 30px;
    @media(min-width: 768px) and (max-width: 1359px){
      margin-top: -11px;
    }
  }
  .desc-under{
    display: none;
  }
  .banner{
    width: 100%;
    height: 500px;
    background-size: cover;
    background-image: url("../assets/5.png");
    background-repeat: no-repeat;
    background-position: center;

  }
}

.collage{
  width: 100%;
  height: 1143px;
  margin: auto;
  min-height: 1800px;
  .text-holder{
    max-width: 1324px;
    margin: auto;

    .profiles-stats{
      font-weight: 500;

      font-size: 90px;
      line-height: 70px;
  
      text-transform: uppercase;
    }
    .caption{
      float: right;
      max-width: 350px;
      height: 90px;
      font-size: 18px;
      line-height: 30px;
      opacity: 0.6;
      margin-top: -200px;
    }

  }
  .profiles-caption-2{

    font-size: 18px;
    line-height: 18px;
    opacity: 0.6;
    margin-top: 45px;
    margin-bottom: 11px;
  }
  .top-left-collage-holder{

    margin: auto;
    max-width: 712px;
    .top-image{
      width: 454.67px;
      height: 472px;
      background: url("../assets/6.1.png");
      background-repeat: no-repeat;
      background-size: cover;
    }
    .bottom-image{
      width: 324.49px;
      height: 407.47px;
      background: url("../assets/6.2.png");
      background-repeat: no-repeat;
      background-size: cover;
      float: right;
      margin-top: -180px;
    }
  }
  .top-right-collage-holder{
    margin: auto;
    max-width: 712px;
    .caption{
      //position: absolute;
      line-height: 30px;
      /* or 125% */
      display: block;

      max-width: 500px;

      color: #FFFFFF;
      font-size: 24px;
      mix-blend-mode: normal;
      opacity: 0.6;
      padding-bottom: 50px;
      @media(min-width: 1201px){
        margin-top: -102px;
      }
      @media(max-width: 767px){
        width: 262px;
        font-size: 18px;
        line-height: 18px;
      }
    }
    .top-image{
      width: 462.5px;
      height: 445px;
      background: url("../assets/6.4.png");
      background-repeat: no-repeat;
      background-size: cover;
      float: left;
    }
    .bottom-image{
      width: 423px;
      height: 426px;
      margin-top: -180px;
      float: right;
      background: url("../assets/6.5-1.png");
      background-repeat: no-repeat;
      background-size: cover;
      float: right;
    }
  }
}

.collage-bottom-left{
  width: 308px;
  height: 384px;
  margin-top: 80px;
  float: right;
  background: url("../assets/6.3.png");
  background-repeat: no-repeat;
  background-size: cover;
  float: left;
}

.collage-bottom-right{
  width: 270px;
  height: 270px;
  width: 270px;
  height: 270px;
  top: -80px;
  right: -80px;
  position: absolute;
  background: url("../assets/6.5.png");
  background-repeat: no-repeat;
  background-size: cover;
}

.collage-bottom{
  width: 195px;
  height: 195px;
  margin-top: 380px;
  float: left;
  background: url("../assets/6.6.png");
  background-repeat: no-repeat;
  background-size: cover;
}

.charts{
  margin-bottom: 45px;
  h4{
    
    max-width: 590px;
    font-size: 50px;
    line-height: 45px;

    margin-top: 50px;
    /* or 97% */
    
    text-transform: uppercase;
    
    color: #FFFFFF;
    font-weight: normal;
  }
  p{
    font-size: 18px;
    line-height: 25px;
    /* or 139% */


    color: #FFFFFF;

    mix-blend-mode: normal;
    opacity: 0.6;
    min-height: 130px;
    max-width: 570px;
  }
  .screen{
    img{
      width: 100%;
      height: 100%;
    }
  }

}

.errorMsg{
  color: red;
  height: 1px;
}

.contact-btn{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  @media(max-width: 767px){

    margin-top: 20px;
  }
  button{
    background-color: transparent;
    border:none;
    color:#7052BF;
    font-weight: 300;
    font-size: 40px;
    line-height: 48px;
    text-transform: uppercase;
  }
  i{
    width: 37.62px;
    height: 24.12px;
    margin-left: 15px;
    img{
      margin-top: -10px;
    }
  }
}

.light-bg{
  background: #E9E3DD;
}
.why{

  padding-left: -20px;
  padding-right: -20px;
  color: #292929;

  margin-bottom: 70px;
  h1{
    font-size: 16px;
    font-size: 20vw;
    /* identical to box height, or 100% */
    font-weight: normal;
    text-transform: uppercase;
    //text-align: center;
    margin: 0;
    padding: 0;
    //font-size: 250px;
    //line-height: 200px;
    text-align: left;
    @media(min-width:731px) and (max-width: 991px){
      padding:15px;
      font-size:  24.5vw !important;
    }

    @media(min-width:495px) and (max-width:730px){
      padding:15px;
      font-size:  23vw !important;
    }

    @media(max-width:494px){
      padding:15px;
      font-size:  23vw !important;
      white-space: nowrap;
    }

  }
  h2{
    margin-top: 100px;
    font-weight: normal;
    font-size: 200px;
    line-height: 126px;
  }
  h5{
    font-weight: normal;
    font-size: 34px;
    line-height: 38px;
    max-width: 355px;
    text-transform: uppercase;
  }
  p{
    font-weight: normal;
    font-size: 20px;
    line-height: 28px;
    max-width: 355px;
    text-align: justify;
    color: #292929;
  }
}

.success{

  color: #292929;

  .caption{
    @media(min-width: 768px){

      margin-bottom: 15px;
    }
    .name{
      &:hover{
        color:#855FE7
      }
      font-size: 45px;
      text-transform: uppercase;
      @media(min-width: 1336px){
        font-size: 65px;
      }


      @media(min-width: 1166px) and (max-width: 1336px){
        font-size: 54px;
        span{
          font-size: 16px;
        }
      }
    }
    span{
      font-size: 18px;
      color: #292929;

    }
  }
  h1{

    font-weight: normal;
    font-size: 180px;
    line-height: 100px;

      margin-top: 50px;
    /* or 78% */

    text-transform: uppercase;
    @media(min-width: 768px){
      margin-bottom: 70px;
    }

  }
  .left-img-holder{
    float: left;
    margin-top: 300px;

    .left-img{
      width: 100%;
      img{
        width: 100%;
      }
    }
    .caption{

      padding-top: 15px;
      .name{
        color: #000;
        &:hover{
          color:#855FE7
        }
      }
      
    }
  }

  .right-img-holder{
    .right-img{
      width: 100%;
      img{
        width: 100%;
      }
    }

    
  }
}
.swatch{

  margin-top: 191px;
  .center-img{
    max-width: 711px;
    width: 100%;
    @media(min-width:767px){
      float: right;
    }
    img{
      width: 100%;
    }
  }
  .caption{
    padding-left: 32px;
    padding-right: 32px;
    span{
      &:nth-child(3){
        max-width: 355px;
        display: block;
      }
    }
  }
}

.panasonic{

  margin-top: 191px;
  .caption{
    padding-right: 32px;
    span{
      &:nth-child(3){
        max-width: 355px;
        display: block;
      }
    }
  }

  .panasonic-img{
    max-width: 100%;
    img{
      width: 100%;
    }
    margin-bottom: 30px;
  }

  a{
    font-weight: normal;
    font-size: 130px;
    text-transform: uppercase;
    color: #292929;
    &:hover{
      color: #855FE7;
      text-decoration: none;
    }
    @media(min-width: 768px) and (max-width: 1359px){
      font-size: 80px;
      text-transform: uppercase;
      line-height: 80px;
    }
  }

}

.clients{
  @media(min-width:768px){
    margin-top: 100px;
  }
  h2{

    font-style: normal;
    font-weight: normal;
    font-size: 160px;
    line-height: 130px;
    /* identical to box height */
    text-transform: uppercase;
    color: #FFFFFF;
    @media(min-width: 768px) and (max-width: 1359px){
      font-size: 110px;
      line-height: 100px;
    }
  }
  .img-client{
    width: 160px;
    // height: 30px;
    // margin-bottom: 100px;
    img{
      width: 100%;
    }
  }
}

.process{
  @media(min-width:768px){
    .Process{
     
      margin-bottom: 100px;
      
    }
  }

  @media(max-width:767px){
    .process-img{
      margin-bottom: 50px;
    }
  }

  h2{

    font-style: normal;
    font-weight: normal;
    font-size: 160px;
    line-height: 110px;
    /* identical to box height */
    text-transform: uppercase;
    color: #FFFFFF;
    max-width: 555px;

    .step{
      width: 200px;
      float: right;
      margin-top: -17px;
      img{
        width: 100%;
      }
    }
  }
  img{
    width: 100%;
  }
  .process-item{

    &:nth-child(1){
      .num{
        color: #B54DC8;
      }
    }
    &:nth-child(2){
      .num{
        color: #B54DC8;
      }
    }
    &:nth-child(3){
      .num{
        color: #855FE7;
      }
    }
    &:nth-child(4){
      .num{
        color: #855FE7;
      }
    }
    &:nth-child(5){
      .num{
        color: #6E81EB;
      }
    }
    &:nth-child(6){
      .num{
        color: #6E81EB;
      }
    }
    &:nth-child(7){
      .num{
        color: #51ABF0;
      }
    }

    &:nth-child(8){
      .num{
        color: #51ABF0;
      }
    }

    height: 117px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .border{
      width: 100%;
      height: 1px;
      background-color: #ffff;
    }
    .text-wrapper{

      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      .txt{
        font-size: 34px;
        line-height: 30px;
        /* or 126% */
        text-transform: uppercase;
        margin-bottom: -12px;
        @media(max-width:767px){
          line-height: 16px;
          font-size: 16px;
       
        }
      }
      .num{
        font-size: 20px;
        /* identical to box height */
        text-transform: uppercase;
        opacity: 0.8;
      }
    }

  }
}

.book{

  @media(max-width:767px){
    margin-top: 60px;
  }
  margin-top: 223px;
  h2{
    font-style: normal;
    font-weight: normal;
    font-size: 122px;
    /* identical to box height */
    text-transform: uppercase;
    color: #FFFFFF;
    line-height: 110px;
    @media(min-width:992px){
      .small{
        font-size: 16px;
      }
    }
    .small{
      display: inline-block;
      max-width: 253px;
      @media(min-width: 1201px){
        float: right;
        display: block;
        margin-top: -10px;
      }
      font-style: normal;
      font-weight: normal;
      font-size: 22px;
      line-height: 22px;
      margin-left: 20px;
      /* or 105% */

      text-transform: uppercase;

      color: #FFFFFF;
      @media(max-width: 767px){
        display: none;
      }
    }

    @media(max-width: 1200px){
      font-size: 70px;
      line-height: 60px;
      .small{
        font-size: 12px;
        max-width: 200px;
      }
    }
  }
  .smallUnder{
    @media(min-width: 768px){
      display: none;
    }
    max-width: 246px;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 18px;
    /* or 112% */

    text-transform: uppercase;

    color: #FFFFFF;

    mix-blend-mode: normal;
  }
  label{
    font-family: Founders Grotesk;
    font-style: normal;
    font-weight: 300px;
    font-size: 28px;
    line-height: 34px;
    /* identical to box height */

    text-transform: uppercase;

    color: #FFFFFF;
    display: block;
  }
  input{
    color: #FFFFFF;
    width: 100%;
    background-color: transparent;
  }
  
}


.hidelarge{
  display: none;
}
.arrowRight{
  width: 126px;
  height: 95px;
  margin-top: -70px;
  @media(min-width: 768px) and (max-width: 1359px){
    width: 83px;
    margin-top: 0px;
  }
  img{
    width: 100%;

  }
}

// @media(min-width:1280px) and (max-width:2800px){
//   .why{
//     h1{
//       font-size: 140px !important;
//     }
  
//   }

// }

@media(min-width: 685px) and (max-width: 767px){
  .clients{
    h2{
      font-size: 15.5vw;
      white-space: nowrap;
    }
  }
}

@media(max-width: 684px){
  .clients{
    h2{
      font-size: 14vw;
      white-space: nowrap;
    }
  }
}
@media (max-width: 767px) {

  .light-bg{
    padding-bottom: 50px;
  }

  .arrowRight{
    width: 28px !important;
    height: 18px !important;
    margin-left: 180px;
    margin-top: -52px;
  }
  .hidemobile{
    display: none;
  }

  .hidelarge{
    display: block;
  }
  .swatch{
    margin-top: 0;
    .caption{
      padding: 0;
    }
  }
  .success{
    h1{
      line-height: 20px !important;
      margin: 0;
      margin-top: 20px !important;
      padding-left: 15px;
    }

    .left-img-holder{
      margin-top: 47px;
    }
    .caption{
      margin-top: 15px;
      margin-bottom: 15px;
    
    }
    .captionTop{
      display: none;
    }
    .captionBottom{
      display: block;
      margin-bottom: 50px;
    }
  }
  .panasonic{

    margin-top: 0;
  }
  .offset-row{
    position: absolute;
    z-index: 2;
    top:-50px;
  }
  .solid-line{
    margin-top: 70px;
    margin-bottom: 130px;
  }

  .hero2{
    margin-top:0;   
  }

  .charts {
    margin-bottom: 2px;
  }

  .why{

    h1{
      font-size: 85px;
      line-height: 64px;

      margin-top: 50px;
    }
    h2{
      margin-top: 32px;
      font-size: 40px;
      line-height: 40px;
    }
    h5{
      font-size: 24px;
      line-height: 23px;
    }
    p{
      font-size: 16px;
      line-height: 19px;
    }
  }
  .success{
    h1{
      font-size: 42px;
      line-height: 35px;

      margin-top: 50px;
    }
  }
  .hero{
    //min-height: 489px !important;
  }
  .small-img{
    display: none;
  }

  .text{
    top:80px;
    right: 0;
    left: 20px;
    h1{
      font-size: 43px !important;
    }
  }
  .bigger-img{
    margin-top:130px !important;
    width: 100% !important;
    height: 336px !important;
    float: right;
    padding-left: 55px;
  }
  .sec-row-image{
    width: 100%;
    left: 0;
    right: 0;
    top:0;
    position: relative;
    .caption{
      display: none;
    }
  }

  .sec-row-text{
    padding: 26px;
    position: relative;
    top:0;
    right: 0;
    left: 0;
    margin: 0;
    .overlay-text{
      font-size: 18px;
      line-height: 18px;
    }
    .caption{
      padding: 0;
      display: block;
      margin: 0;
      font-size: 12px;
      line-height: 12px;
      margin-top: 30px;
      max-width: 233px;
    }
  }
  .guess-work{

    .offset-row{
      div{
        margin-bottom: -10px;
      }
    }
    min-height: 780px!important;
    margin: 0px !important;
    .jumbo{
      font-size: 42px;
      line-height: 5px;
    }
  }
  .top-left-collage-holder{
    max-width: 315px !important;
    margin: auto;
    margin-bottom: 125px !important;
    .top-image{
      width: 182.52px !important;
      height: 189.47px !important;
    }
    .bottom-image{
      width: 159.64px !important;
      height: 159.64px !important;
      margin-top: -80px !important;
    }
  }
  .top-right-collage-holder{
    max-width: 315px !important;
    margin: auto;
    margin-top: 30px !important;
    .top-image{
      width: 211.46px !important;
      height: 255.86px !important;
    }
    .bottom-image{
      width: 164px !important;
      height: 165px !important;
      margin-top: -80px !important;
    }
    .caption{
      max-width: 242px !important;
      font-size: 18px;
      padding-bottom: 11px !important;
    }
  }

  .collage{
    height: unset;
    min-height: unset;
    .offset-row{
      top:-100px
    }
  }
  .charts{
    h4{
      font-size: 28px;
      line-height: 28px;
      margin-top: 100px;
    }
    p{
      font-size: 18px;
      line-height: 18px;
    }
    .screen{
      img{
        width: 100%;
        height: 100%;
      }
    }
  
  }
  .left-img-holder{
    float: none;
    .left-img{
      width: 100%;
    }
    .caption{
      .name{
        font-size: 28px;
      }
    }
  }

  .left-img-holder, .right-img-holder, .swatch, .panasonic{
    .caption{

      margin-top: 15px;
      .name{
        font-size: 28px;
      }
   
      span{
        font-size: 16px;
        line-height: 18px;
        display: inline !important;
      }
    }
  }
  .panasonic{
    a{
      font-size: 32px;
    }
  }
  .clients{
    .mobileHead{
      display: block;
      text-align: center;
    }

    .desktopHead{
      display: none;
    }

    .img-client{
      width: 120px;
      height: 40px;
      margin: auto;
      margin-bottom: 40px;

    }
    .clientCol{
      &:nth-child(even){
        .img-client{
          margin-right: 0 !important;
        }
      }
      &:nth-child(odd){
        .img-client{
          margin-left: 0 !important;
        }
      }
    }
    
  }
  .process{
    h2{
      font-size: 72px !important;
      line-height: 50px;
      max-width: 326px !important;
    }
    .process-item{
      .txt{
        font-weight: 300;
        font-size: 16px;
      }
    }
  }
  .book{
    h2{
      font-size: 50px;
      line-height: 50px;
    }
    label{
      font-weight: 300;
      font-size: 20px;
    }
  }

}

@media(min-width:768px){
  .success{
    .captionBottom{
      display: none;
    }
  }
  .clients{
    .mobileHead{
      display: none;
    }
    // .desktopHead{
    //   display: none;
    // }
  }
}

@media (min-width:768px) and (max-width:1200px){

  .profiles-caption-2{
    max-width: 242px !important;
    margin-top: 45px !important;
  }
  .top-left-collage-holder{
    max-width: 579px !important;
    margin-bottom: 200px !important;
    .top-image{
      width: 303.11px !important;
      height: 314px  !important;
    }
    .bottom-image{
      width: 303.11px !important;
      height: 314px  !important;
    }
  }
  .top-right-collage-holder{
    max-width: 579px !important;
    margin-top: 45px !important;
    .top-image{
      width: 303.11px !important;
      height: 314px  !important;
    }
    .bottom-image{
      width: 303.11px !important;
      height: 314px  !important;
      margin-top: -180px;
    }
  }
  .collage{
    min-height: 750px;
  }
  .charts{
    h4{
      margin-top: 100px;
    }
  }

 
}


@media (max-width: 991px) {
  main{
    padding: 0px !important;
    padding-top: 0;
  }
  .collage-bottom-left, .collage-bottom-right, .collage-bottom{
    display: none;
  }

}

@media (min-width: 992px){
  .wrapper{
    padding: 103px !important;
    margin-top: -60px;
  }
  main{
    padding-top: 0;
  }
  .profiles-caption-2{
    display: none !important;
  }
}


@media (max-width: 767px) {
  .process{
    h2{
      line-height: 45px !important;
      .step{
        margin-bottom: -28px;
      }
    }
  }
}

@media (min-width: 768px) and (max-width:1999px){
  .process{
    h2{
      .step{
        width: 160px !important;
        margin-bottom: 40px;
      }
    }
  }
}

@media(max-width:1336px){
  .clients{
    .img-client{
      width: 200px;
    }
    h2{
      font-size: 100px;
    }
  }
}

@media(max-width:767px){
  .clients{
    .img-client{
      width: 150px;
      height: 50px;
    }
    h2{
      font-size: 50px;
    }
  }
}
@media(min-width: 992px) and (max-width:1200px){
  .text{
    h1{

      font-size: 124px;
    }
  }
  .small-img{
    top:60px;
  }
  .bigger-img{
    width: 700px;
    margin-top: 245px;
  }
  .sec-row-text{
    right: 190px;
    top: 100px;
    .overlay-text{
      font-size: 50px;
      line-height: 50px;
    }
  }
  .sec-row-image{
    width: 395px;
    height: 415px;
  }

  
  .third-row-image{
    width: 385px;
    height: 392px;
    .caption{
      font-size: 28px;
      line-height: 28px;
    }
  }
  .guess-work{
    min-height: 800px;
    .desc{
      font-size: 16px;
      max-width: 300px;
    }
  }
  .collage{
    min-height: 1600px;
  }
  .charts{
    h4{
      font-size: 36px;
      min-height: 110px !important;
    }
  }
  .clients{
    .img-client{
      width: 200px;
      height: 0px;
    }
    h2{
      font-size: 100px;
    }
  }
  .process{
    margin-top: 50px;
  }
  .book{
    label{
      font-size: 20px;
    }
  }
  // .small-img{
  //   display: none;
  // }
}


@media(max-width: 1023px){
  .small-img{
    display: none;
  }
}
@media (max-width: 1023px) {

  .process{
    h2{
      .step{
        width: 110px;
        float: right;
        margin-bottom: -30px;
        margin-top: unset;
        img{
          width: 100%;
        }
      }
    }
  }
  :global(.container-fluid, .col){
    padding: 0;
  }
 
  .hero2{
    margin-top:0;
    
    margin-left: -30px !important;
    margin-right: -30px !important;
  }
  .sec-row-image{
    width: 100%;
    left: 0;
    right: 0;
    margin-top: 0;
    position: relative;
    .caption{
      display: none;
    }
  }
  .third-row-image{
    width: 219px;
    height: 236px;  
    margin: auto;
    margin-left: 12px;
    .caption{
      font-size: 16px;
      line-height: 16px;
      max-width: 211px;
    }
  }
  .sec-row-text{
    padding: 26px;
    position: relative;
    top:20px;
    right: 0;
    left: 0;
    margin: 0;
    .animated{
      margin-bottom: -10px !important;
    }
    div{
      font-size: 18px;
      line-height: 18px;

    }
    .caption{
      padding: 0;
      display: block;
      margin: 0;
      font-size: 12px;
      line-height: 12px;
      margin-top: 30px;
    }
  }

  .text{
    h1{
      font-size: 80px;
    }
  }

  .bigger-img{
    margin-top: 100px;
    width: 700px;
    height:897px;
    left: unset;
    position: relative;
    .caption{
      display: block;
      transform: rotate(-90deg);
      position: absolute;
      left: -30px;
      bottom: 80px;
      text-transform: uppercase;
      opacity: 0.5;
      line-height: 13px;
    }
  }

  .guess-work{
    min-height: 900px;
    .desc{
      display: none
    }
    .desc-under{
      display: block;
      position: absolute;
      font-size: 18px;
      line-height: 18px;
      /* or 100% */
      opacity: 0.6;
      margin-top: 20px;

    }
  }


  .collage{

    .text-holder{

      .profiles-stats{
        font-size: 43px;
        line-height: 32px;

      }
      .caption{
        display: none;
      }
    }
  }
  .process, .clients, .book{
    padding: 20px !important;
  }

  .arrow-wrapper{
    width: 38.74px !important;
    height: 33.75px !important;
  }

}

@media (min-width:992px) and (max-width:1200px){
  .profiles-stats{
    margin-top: 100px;
  }

  .charts{
    margin-bottom: 45px;
    h4{
      margin-top: 200px;
      min-height: 235px;
    }
    p{
      min-height: 200px;
    }
  }
}

@media (min-width:1201px) and (max-width:1274px){
  .charts{
    h4{
      margin-top: 200px;
      min-height: 235px;
    }
    p{
      min-height: 200px;
    }
  }
}

@media(min-width:992px) and (max-width:1335px){
  .process{
    h2{
      font-size: 130px;
      line-height: 92px !important;
    }
  }
}
@media(min-width:768px) and (max-width:1023px){
  .text{
    left: 15px;
  }
  .bigger-img{
    width: 100%;
    float: right;
    padding-left: 35px;
    .caption{
      left: -55px;
    }
  }
}