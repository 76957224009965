footer{
  padding-bottom: 32px;
  margin: auto;
  font-size: 22px;
  line-height: 31px;

  span,.social{
    text-transform: uppercase;
  }
  a{
    text-decoration: none;
    color: #FFFFFF !important;
  }
  .allrights{
    text-align: center;
  }
  .social{
    width: 100%;
    text-align: center;
    font-size: 18px;
  }

  .socialDiv{
    display: flex;
    align-items: flex-end;
  }
    
  .contact{
    text-align: left;
    font-size: 18px;
    display: flex;
    align-items: flex-end;
    text-transform: uppercase;
  }

  .design{
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    font-size: 18px;
    text-align: center;
    span {
      text-transform: none;
    }
  }

  .allrightsmobile {
    text-align: left;
    font-size: 14px;
    line-height: 22px;
  }

  .allrightsmobile1{
    display: none;
  }

  .companyDetailsMobile{
    font-weight: 300;
    font-size: 18px;
    line-height: 19px;
    opacity: .5;
  }

  @media (max-width:991px){
    font-size: 18px;
    line-height: 22px;
    .social, .socials, .contact, .design{
      margin-top: 15px;
      text-align: left;
    }

    .design{
      justify-content: left;
    }

    .allrightsmobile {
      display: none;
    }
  }

  @media (max-width:767px){
    .allrightsmobile {
      display: none;
    }
    
    .design{
      justify-content: left;
    }
  }


  @media (min-width:768px) {
    .contact{
      margin-top: 1rem;
    }

    .socialDiv, .contact, .designedBy{
      a {
        font-size: 18px;
      }
    }

    .companyDetailsMobile {
      font-size: 14px;
      opacity: .5;
    }
  }

  @media (min-width: 1200px) {
    .socialDiv, .contact, .designedBy{
      a {
        font-size: 18px;
      }
    }

    .socialDiv {
      flex: 0 0 29%;
    }

    .design {
      flex: 0 0 23%;
    }
  }
  
}

