.slide{


  ol{

    list-style-type: none;
    bottom: -60px !important;

    li{
      counter-increment: customlistcounter;
      text-indent: 3px;
      color: #fff;
      font-family: Founders Grotesk;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 17px;
      background-color: transparent;
      margin-left: 20px;
      margin-right: 20px;
      &:before{
        content: counter(customlistcounter, decimal-leading-zero) " ";
        float: left;
        width: 3em;
      }
      
    }
    &:first-child{
      counter-reset: customlistcounter;
    }
    

  }
}