@import url('https://fonts.googleapis.com/css?family=Open+Sans');

.urlControlsBg {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: var(--gray-white);
    box-shadow: 0-1px 0#dadce0 inset;
  }
  .rightLockedIcons {
    position: absolute;
    top: calc(50% - 11px);
    right: 14px;
    width: 51px;
    height: 22px;
    overflow: hidden;
  }
  .urlFillBg {
    position: absolute;
    width: 100%;
    top: calc(50% - 14px);
    right: 0;
    left: 0;
    border-radius: var(--br-sm);
    background-color: var(--color-whitesmoke-100);
    height: 28px;
  }
  .iconFavorite {
    position: absolute;
    top: calc(50% - 8px);
    right: 10px;
    width: 16px;
    height: 16px;
  }
  .domaincom,
  .subdomain {
    position: relative;
    color: white;
    text-decoration: none;
  }
  .domaincom2 {
    color: #7B73F4;
  }
  .subdomain {
    font-size: var(--body-regular-size);
    letter-spacing: 0.25px;
    font-family: var(--font-roboto);
    color: var(--color-dimgray);
    display: none;
  }
  .domaincomParent {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  }
  .iconSecure,
  .urlText {
    position: absolute;
    top: calc(50% - 8px);
    left: 33px;
    width: 165px;
    height: 16px;
  }
  .iconSecure {
    top: calc(50% - 6px);
    left: 11px;
    width: 12px;
    height: 12px;
  }
  .leftLockedIcons,
  .urlBar {
    position: absolute;
    overflow: hidden;
  }
  .urlBar {
    width: calc(100% - 215px);
    top: calc(50% - 14px);
    right: 81px;
    left: 134px;
    height: 28px;
  }
  .leftLockedIcons {
    top: calc(50% - 8px);
    left: 12px;
    width: 109px;
    height: 16px;
  }
  .toolbarUrlControls1 {
    position: absolute;
    width: 100%;
    top: 0;
    right: 0;
    left: 0;
    height: 38px;
    overflow: hidden;
  }
  .broswerControlBarBg,
  .toolbarUrlControls {
    position: absolute;
    width: 100%;
    top: 42px;
    right: 0;
    left: 0;
    height: 38px;
  }
  .broswerControlBarBg {
    height: 100%;
    top: 0;
    bottom: 0;
    border-radius: var(--br-5xs) var(--br-5xs) 0 0;
    background-color: var(--color-gainsboro-100);
  }
  .curveLIcon,
  .image1422Icon {
    position: relative;
    width: 6px;
    height: 8px;
  }
  .image1422Icon {
    width: 7.1px;
    object-fit: cover;
  }
  .image1422Wrapper {
    border-radius: var(--br-81xl);
    background-color: var(--primary-primary-2);
    width: 16px;
    height: 16px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  .tabName {
    position: relative;
    letter-spacing: 0.2px;
  }
  .iconClose {
    position: relative;
    width: 18px;
    height: 18px;
  }
  .curveLParent,
  .faviconTextIcons {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }
  .faviconTextIcons {
    border-radius: var(--br-5xs) var(--br-5xs) 0 0;
    background-color: var(--gray-white);
    overflow: hidden;
    align-items: center;
    padding: var(--padding-5xs);
    gap: var(--gap-4xs);
  }
  .curveLParent {
    align-items: flex-start;
  }
  .iconPlus {
    position: relative;
    width: 20px;
    height: 20px;
  }
  .frameParent {
    position: absolute;
    top: 0;
    left: -6px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: var(--gap-5xs);
  }
  .browserTabWithPlus,
  .tabPlus {
    position: absolute;
    top: calc(50% - 17px);
    left: 8px;
    width: 131px;
    height: 34px;
  }
  .tabPlus {
    top: calc(50% - 13px);
    left: 72px;
    width: 167px;
  }
  .browserControlsIcon {
    position: absolute;
    top: calc(50% - 5.5px);
    left: 13px;
    width: 52px;
    height: 12px;
    overflow: hidden;
  }
  .broswerControlBar {
    height: 100%;
    bottom: 0;
    overflow: hidden;
  }
  .broswerControlBar,
  .browserUrlControls,
  .toolbarBrowserControls {
    position: absolute;
    width: 100%;
    top: 0;
    right: 0;
    left: 0;
  }
  .toolbarBrowserControls {
    height: 52.5%;
    bottom: 47.5%;
    font-size: var(--font-size-xs);
    color: var(--color-darkslategray-100);
    font-family: var(--font-roboto);
  }
  .browserUrlControls {
    height: 80px;
    overflow: hidden;
  }
  .unionIcon {
    position: relative;
    width: 236px;
    height: 52px;
  }
  .logo {
    width: 312px;
    height: 114px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .icon,
  .icon1 {
    position: relative;
    width: 16px;
    height: 16px;
  }
  .icon1 {
    display: none;
  }
  .button1,
  .content {
    display: flex;
    align-items: center;
  }
  .content {
    flex-direction: row;
    justify-content: flex-start;
    gap: var(--gap-5xs);
  }
  .button1 {
    border-radius: var(--br-81xl);
    background-color: var(--primary-primary-2);
    flex-direction: column;
    justify-content: center;
    padding: var(--padding-xs-5) var(--padding-base);
  }
  .appstoreIcon {
    position: relative;
    width: 145.4px;
    height: 42px;
  }
  .appstore {
    justify-content: flex-end;
    gap: var(--gap-xs);
  }
  .appstore,
  .button,
  .navigation {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .button {
    justify-content: flex-end;
    gap: var(--gap-21xl);
  }
  .navigation {
    position: absolute;
    top: 0px;
    left: 0;
    background-color: var(--gray-white);
    box-shadow: 0 4px 18px rgba(0, 0, 0, 0.14);
    width: 1440px;
    height: 114px;
    justify-content: space-between;
    padding: 0 var(--padding-37xl) 0 0;
    box-sizing: border-box;
    color: var(--gray-white);
    width: 100%;
  }
  .privacyPolicy {
    position: relative;
    line-height: 150%;
  }
  .button3,
  .button4 {
    display: flex;
    align-items: center;
  }
  .button4 {
    border-radius: var(--br-11xs);
    flex-direction: column;
    justify-content: center;
    padding: var(--padding-xs-5) 0;
  }
  .button3 {
    flex-direction: row;
    justify-content: flex-start;
    gap: var(--gap-21xl);
    color: var(--primary-primary-2);
  }
  .copywrite2023KaTechnologiParent,
  .footer {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  .footer {
    flex-direction: column;
    padding: 0 var(--padding-37xl);
    box-sizing: border-box;
    z-index: 0;
  }
  .frameChild {
    position: absolute;
    margin: 0 !important;
    top: -30.5px;
    width: auto;
    left: 60px;
    right: 60px;
    height: 1px;
    z-index: 1;
    object-fit: fill;
    background-color: #949494;
  }
  .footerParent {
    position: absolute;
    bottom: 0;
    height: 100px;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: var(--gap-3xs);
    font-size: var(--body-3-bold-size);
    color: var(--gray-gray-500);
    width: 100%;
  }
  .group8983 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: scale-down;
  }
  .mockupIphone131 {
    position: absolute;
    top: 0;
    left: 247.6px;
    width: 216.7px;
    height: 439.4px;
    object-fit: scale-down;
  }
  .groupChild {
    position: absolute;
    top: 354.6px;
    left: 136.1px;
    width: 137.5px;
    height: 38.2px;
  }
  .campaignCard1,
  .viewProfile1 {
    position: absolute;
    top: 154.6px;
    left: 46.5px;
    border-radius: var(--br-5xs);
    width: 174.6px;
    height: 200.2px;
    object-fit: cover;
  }
  .viewProfile1 {
    top: 169.7px;
    left: 477.8px;
    width: 195.6px;
    height: 307.1px;
  }
  .airplaneIcon,
  .groupItem {
    position: absolute;
    top: 35.6px;
    left: 441.2px;
    width: 152.6px;
    height: 138px;
  }
  .airplaneIcon {
    height: 100%;
    width: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    max-width: 100%;
    overflow: hidden;
    max-height: 100%;
    object-fit: cover;
  }
  .affinityIcon {
    position: relative;
    width: 14px;
    height: 14px;
  }
  .selection,
  .selection1,
  .selection2,
  .selection3 {
    position: absolute;
    top: 208.1px;
    left: 0;
    border-radius: var(--br-81xl);
    background-color: var(--color-lavender);
    box-shadow: 0 4px 12px rgba(19, 18, 38, 0.2);
    border: 1px solid var(--primary-primary-2);
    box-sizing: border-box;
    width: 77.9px;
    height: 29.2px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: var(--padding-7xs) var(--padding-5xs);
    gap: var(--gap-5xs);
  }
  .selection1,
  .selection2,
  .selection3 {
    top: 424.1px;
    left: 89px;
    width: 107.1px;
  }
  .selection2,
  .selection3 {
    top: 51.1px;
    left: 598px;
    width: 120.5px;
  }
  .selection3 {
    top: 226.4px;
    left: 604.3px;
    width: 85.2px;
  }
  .mockupIphone131Parent {
    position: relative;
    width: 818.5px;
    height: 460.9px;
  }
  .areYouA,
  .monetizeYourContent {
    align-self: stretch;
    position: relative;
    width: 100%;
  }
  .monetizeYourContent {
    font-size: var(--body-3-bold-size);
    line-height: 150%;
  }
  .areYouACreatorParent {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-xl);
  }
  .areYouABrandParent {
    align-self: stretch;
    background: yellow;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-xl);
  }
  .frameItem {
    position: relative;
    border-radius: 50%;
    background-color: var(--primary-primary-2);
    width: 6px;
    height: 6px;
  }
  .directChat {
    flex: 1;
    position: relative;
    line-height: 150%;
  }
  .ellipseParent {
    align-self: stretch;
    flex-direction: row;
    align-items: center;
    gap: var(--gap-base);
  }
  .ellipseGroup,
  .ellipseParent,
  .frameDiv,
  .frameParent1 {
    display: flex;
    justify-content: flex-start;
  }
  .ellipseGroup {
    flex-direction: row;
    align-items: center;
    gap: var(--gap-base);
  }
  .frameDiv,
  .frameParent1 {
    align-self: stretch;
    flex-direction: column;
    align-items: flex-start;
  }
  .frameParent1 {
    gap: var(--gap-xl);
    font-size: var(--body-3-bold-size);
  }
  .frameDiv {
    gap: var(--gap-5xl);
  }
  .getKolsasiaNow {
    position: relative;
    line-height: 120%;
  }
  .getKolsasiaNowParent {
    flex-direction: column;
    align-items: flex-start;
    gap: var(--gap-base);
    font-size: var(--body-3-bold-size);
    color: var(--gray-gray-500);
  }
  .frameContainer,
  .getKolsasiaNowParent,
  .groupParent {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    align-self: center;
    
  }
  .frameContainer {
    flex-direction: column;
    align-items: flex-start;
    gap: var(--gap-29xl);
    width: 50%;
    font-size: var(--subheadline-bold-size);
    color: var(--gray-black);
  }
  .groupParent {
    flex-direction: row;
    align-items: center;
    gap: 95px;
  }
  .loremIpsumDolor {
    align-self: stretch;
    position: relative;
    line-height: 150%;
    display: none;
    height: 331px;
    flex-shrink: 0;
  }
  .clearReportingOf {
    margin: 0;
  }
  .ellipseParent4 {
    display: none;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: var(--gap-base);
  }
  .frameParent3 {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-5xl);
    font-size: var(--subheadline-bold-size);
    color: var(--gray-black);
  }
  .icon11 {
    position: relative;
    width: 24px;
    height: 24px;
  }
  .button8,
  .content5 {
    display: flex;
    align-items: center;
  }
  .content5 {
    flex-direction: row;
    justify-content: flex-start;
    gap: 4px;
  }
  .button8 {
    border-radius: var(--br-81xl);
    background-color: var(--primary-primary-2);
    border: 1px solid var(--primary-primary-2);
    flex-direction: column;
    justify-content: center;
    padding: var(--padding-xs-5) var(--padding-base);
    font-size: var(--body-2-regular-size);
    color: var(--gray-white);
  }
  .loremIpsumDolorSitAmetCoParent,
  .registerAsAMerchantNowParent {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-base);
  }
  .loremIpsumDolorSitAmetCoParent {
    width: 50%;
    gap: var(--gap-29xl);
  }
  .groupInner,
  .image1568Icon {
    position: absolute;
    top: 0;
    left: 0;
    width: 694.3px;
    height: 324.1px;
    object-fit: cover;
  }
  .groupInner {
    top: 141.5px;
    left: 176.3px;
    border-radius: var(--br-7xs);
    width: 26.2px;
    height: 26.3px;
  }
  .imagegroup8987 {
    position: absolute;
    top: 0;
    left: 10%;
    box-shadow: 0 4px 14px rgba(0, 0, 0, 0.15);
    width: 90%;
    height: 100%;
    object-fit: scale-down;
  }
  .image1568Parent {
    position: absolute;
    top: 87.1px;
    left: 0;
    box-shadow: 0 4px 14px rgba(0, 0, 0, 0.15);
    width: 694.3px;
    height: 324.1px;
  }
  .image1569Icon {
    position: absolute;
    top: -9px;
    left: 277.8px;
    border-radius: var(--br-7xs);
    width: 240.9px;
    height: 205.4px;
    object-fit: cover;
  }
  .groupContainer {
    position: relative;
    width: 40%;
    height: 411.2px;
  }
  .frameParent2 {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    font-size: var(--body-3-bold-size);
    color: var(--gray-gray-500);
  }
  .frameGroup {
    position: absolute;
    top: 180px;
    left: 62px;
    right: 62px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 96px;
    font-size: var(--font-size-xs);
    color: var(--primary-primary-2);
  }
  .landingPage {
    position: relative;
    border-radius: var(--br-5xs);
    background-color: var(--gray-white);
    border: 1px solid var(--color-whitesmoke-200);
    box-sizing: border-box;
    width: 100%;
    height: 1818px;
    overflow: hidden;
    text-align: left;
    font-size: var(--body-2-regular-size);
    color: var(--gray-black);
    font-family: 'Open Sans', cursive;
  }
  