.main{
  padding: 103px;
}


@media (max-width: 767px) {
  .main{
    padding: 20px;
    padding-top: 103px;
  }
}