@import "~slick-carousel/slick/slick.scss";
@import "~slick-carousel/slick/slick-theme.scss";

.flat-slide{

  :global(.slick-slide){
    
    width: 647px;
    height: 693px;
    padding: 15px;
    img{
      width: 100%;
      height: 100%;
    }
    //&:hover{
    //  position: relative;
    //  width: 725.29px;
    //  height: 969px;
    //  padding: 5px;
    //}
    
  }

  // :global(.slick-current){
  //     //width: 700px !important;
  //     //transform: scale(1.1);
  //     padding: 30px;
    
  // }
  :global(.slick-dots){
    width: 100%;
    display: flex !important;
    flex-direction: row;
    justify-content: space-between;
    li{
      width: 100%;
      margin: 0;
      button{
        width: 100%;
        border-bottom: solid 1px #ffff;
        &:before{
          content: "";
        }
      }
    }
    :global(.slick-active) {
      button{
        border-bottom: solid 6px #ffff;
        margin-top: 2px;
      }
    }

  }
  :global(.slick-arrow){
    display: none !important;
  }
  // display: flex;
  // flex-direction: row;
  // justify-content: space-between;

  // :global(.slick-slide){
  //   width: 647px;
  //   height: 693px;
  // }
  // :global(.slick-current){
  //   width: 725.29px!important;
  //   height: 969px;
  // }

  @media (max-width:1200px){
    :global(.slick-slide){
    
      width: 237px;
      height:255px;
      padding: 5px;
      
    }
    // :global(.slick-current){
    //   //width: 700px !important;
    //   transform: scale(2.2);
    //   padding: 5px;
    
    // }
  }
}