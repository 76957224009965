.main{
  padding: 103px;

  @media(min-width:767px){
    .cols{
      &:nth-child(even){
        text-align: right;
      }
    }
  }

  .title-wrapper{
    position: absolute;
    z-index: 3;
    //top:143px;
    text-shadow: 1px 1px #000;
  }
  @media(min-width:767px){
    margin-top: 100px;
    position: relative;
    .title-wrapper{
      top: 58px;
    }
  }
  .title{

    h1{
      font-style: normal;
      font-weight: 500;
      font-size: 200px;
      text-transform: uppercase;
      line-height: 142px;
    }
    span{
      font-style: normal;
      font-weight: normal;
      font-size: 34px;
      line-height: 43px;
      /* or 126% */

      text-transform: uppercase;

      color: #FFFFFF;

      opacity: 0.3;
      max-width: 415px;
      display: block;
      margin-left: 14px;
    }
  }
  .image-wrapper{
    width: 100%;
    img{
      object-fit: cover;
      width: 100%;
      height: 859px;
    }
  }
  .description{
    padding-top: 15px;
    padding-bottom: 15px;
    font-size: 34px;
    line-height: 43px;
    /* or 126% */

    text-transform: uppercase;
  }
  .attributes{

    height: 117px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .border{
      width: 100%;
      height: 1px;
      background-color: #ffff;
    }
    .text-wrapper{

      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .label{
        font-size: 34px;
        line-height: 43px;
        /* or 126% */
        
        text-transform: uppercase;
        
        
        opacity: 0.3;
      }
      .value{
        font-size: 34px;
        line-height: 43px;
        /* or 126% */
        
        text-align: right;
        text-transform: uppercase;
      }
    }

  }
  .nums{
    margin-top: 100px;
  }

  .numbers{
    margin-top: 100px;
    margin-bottom: 100px;
    .num{
      font-style: normal;
      font-weight: normal;
      font-size: 200px;
      line-height: 126px;
      /* identical to box height, or 63% */

      text-transform: uppercase;
      max-width: 650px;
      position: relative;
      .roi{
        position: absolute;
        right: -150px;
        top: -130px;
        max-width: 243px;
        img{
          width: 100%;
        }
      }
      @media(max-width: 767px){
        max-width: 300px;
        position: relative;
        .roi{
          position: absolute;
          right: -50px;
          top: -35px;
          max-width: 130px;
        }
      }
    }
    .txt{
      font-style: normal;
      font-weight: normal;
      font-size: 34px;
      line-height: 37px;
      /* or 109% */

      text-transform: uppercase;
    }
  }
}

@media (max-width: 767px){
    .main{
      padding: 20px;
      .title{
        h1{
          font-size: 60px;
          line-height: 45px;
        }
        span{
          font-size: 12px;
          line-height: 15px;
        }
      }
      .description{
        font-size: 16px;
        line-height: 20px;
      }
      .attributes{
        .text-wrapper{
          .label, .value{
            font-weight: 300;
            font-size: 12px;
            line-height: 14px;
          }
        }
      }
      .numbers{
        .num{
          font-size: 80px;
          line-height: 126px;
        }
        .txt{
          font-size: 24px;
          line-height: 28px;
        }
      }
    }
}

.sentiments{
  margin-top: 100px;
  margin-bottom: 100px;
  h2{
    font-weight: normal;
    font-size: 150px;
    text-transform: uppercase;
  }

  .username{
    color: #885EE6;
  }
  .usernameMain{
    color: #885EE6;
  }
  .username, .small_comment{
    font-size: 20px;
    line-height: 22px;
    display: block;
    text-transform: uppercase;
  }
  .small_comment{
    margin-bottom: 10px;
  }
  .usernameMain, .comment{
    font-size: 40px;
    line-height: 30px;
    display: block;
    text-transform: uppercase;
  }
  .comment{
    margin-bottom: 100px;
  }

}

@media (max-width: 767px){
  .sentiments{
    padding: 20px;
    h2{
      font-size: 60px;
      line-height: 45px;
    }
    .username, .comments{
      font-size: 24px;
    }
  }
  .image-wrapper{
    margin-top: 20px;
    margin-right: -30px;
    float: right;
    span{
      transform: rotate(-90deg);
      float: left;
      margin-left: -85px;
      margin-top: 72px;
      width: 198px;
  
    }
  }
  .title-wrapper{
    span{
      transform: rotate(-90deg);
      margin-left: -100px;
      max-width: 215px !important;
      transform: rotate(-90deg);
      /* left: 0; */
      float: left;
      margin-top: 80px;
    }
  }
  .main{
    margin-top: 50px;
  }
  
}


@media (min-width:768px) and (max-width:1200px){
  .main{
    .title{
      h1{
        font-size: 130px;
        line-height: 120px;
      }
    }
  }
  .sentiments{
    h2{
      font-size: 90px;
    }
  }
  .nums, .numbers{
    .num{
      font-size: 130px !important;
      min-height: unset;
    }
    .txt{
      font-size: 25px !important;
    }
  }
      .txt{
      font-size: 25px;
    }

  .roi{
    right: 0px !important;
    max-width: 200px;
    top:-90px !important;
  }
  
}