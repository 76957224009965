.image1424Icon {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  object-fit: cover;
}
.image1425Icon {
  width: 13px;
  height: 10px;
  object-fit: cover;
}
.appkolsasia,
.image1425Icon {
  position: relative;
}
.image1425Parent {
  position: absolute;
  height: 27.84%;
  width: 80%;
  top: 59.18%;
  right: 12.74%;
  bottom: 12.99%;
  left: 7.26%;
  background-color: var(--color-whitesmoke);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-5xs) 0;
  box-sizing: border-box;
  gap: var(--gap-9xs);
}
.image1424Parent {
  position: absolute;
  top: 0;
  left: 0;
  width: 390px;
  height: 97px;
}
.logoIcon {
  position: relative;
  width: 172px;
  height: 29px;
}
.icon {
  width: 14px;
  height: 14px;
}
.button1,
.icon,
.icon1 {
  position: relative;
}
.button1 {
  line-height: 150%;
  color: white;
  text-decoration: none;
}
.icon1 {
  width: 14px;
  height: 14px;
  display: none;
}
.content {
  flex-direction: row;
  justify-content: flex-start;
  gap: var(--gap-5xs);
}
.button,
.content,
.navigation {
  display: flex;
  align-items: center;
}
.button {
  border-radius: var(--br-81xl);
  background-color: var(--primary-primary-2);
  flex-direction: column;
  justify-content: center;
  padding: 8px 16px;
}
.navigation {
  padding: 16px;
  background-color: var(--gray-white);
  box-shadow: 0 4px 14px rgba(0, 0, 0, 0.14);
  width: auto;
  flex-direction: row;
  justify-content: space-between;
  box-sizing: border-box;
  font-size: var(--body-regular-size);
  color: var(--gray-white);
}
.group89831 {
  position: relative;
  width: 358px;
  height: 236px;
  object-fit: cover;
}
.areYouA,
.monetizeYourContent {
  align-self: stretch;
  position: relative;
  padding-right: 16px;
}
.monetizeYourContent {
  font-size: var(--body-2-regular-size);
}
.areYouACreatorParent {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-xl);
}
.frameChild {
  position: relative;
  border-radius: 50%;
  background-color: var(--primary-primary-2);
  width: 6px;
  height: 6px;
}
.directChat {
  flex: 1;
  position: relative;
}
.ellipseParent {
  align-self: stretch;
}
.ellipseGroup,
.ellipseParent {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-base);
}
.frameContainer,
.frameDiv {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding-right: 8px;
}
.frameDiv {
  gap: var(--gap-xl);
  font-size: var(--body-2-regular-size);
}
.frameContainer {
  gap: var(--gap-5xl);
}
.getKolsasiaNow {
  position: relative;
  line-height: 120%;
}
.appstoreIcon {
  position: relative;
  width: 145.4px;
  height: 42px;
}
.appstore {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 12px;
}
.getKolsasiaNowParent {
  gap: var(--gap-base);
  font-size: var(--body-3-bold-size);
  color: var(--gray-gray-500);
}
.frameGroup,
.getKolsasiaNowParent,
.group89831Parent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.frameGroup {
  align-self: stretch;
  gap: var(--gap-21xl);
  padding: 16px;
}
.group89831Parent {
  width: 100%;
  padding: var(--padding-5xl) var(--padding-base) 0;
  box-sizing: border-box;
  gap: var(--gap-xl);
}
.groupChild,
.image1568Icon {
  position: absolute;
  top: 0;
  left: 0;
  width: 358px;
  height: 167.1px;
  object-fit: cover;
}
.groupChild {
  top: 73px;
  left: 90.9px;
  border-radius: 6px;
  width: 13.5px;
  height: 13.5px;
}
.image1568Parent {
  position: absolute;
  top: 45.7px;
  left: 0;
  box-shadow: 0 4px 14px rgba(0, 0, 0, 0.15);
  width: auto;
  height: 167.1px;
  padding: 16px;
}
.image1569Icon {
  position: absolute;
  top: -8.5px;
  left: 149.2px;
  border-radius: 3px;
  width: 135.8px;
  height: 117.6px;
  object-fit: cover;
}
.groupContainer {
  position: relative;
  width: 100%;
  height: 212.8px;
}
.clearReportingOf {
  margin: 0;
}
.icon2,
.icon3 {
  position: relative;
}
.icon2 {
  width: 16px;
  height: 16px;
  display: none;
}
.icon3 {
  width: 24px;
  height: 24px;
}
.button2,
.content1 {
  display: flex;
  align-items: center;
}
.content1 {
  flex-direction: row;
  justify-content: flex-start;
  gap: var(--gap-9xs);
}
.button2 {
  border-radius: var(--br-81xl);
  background-color: var(--primary-primary-2);
  border: 1px solid var(--primary-primary-2);
  flex-direction: column;
  justify-content: center;
  padding: var(--padding-xs-5) var(--padding-base);
  color: var(--gray-white);
}
.registerAsAMerchantNowParent {
  gap: var(--gap-base);
  font-size: var(--body-2-regular-size);
  color: var(--gray-gray-500);
}
.frameParent,
.groupParent,
.registerAsAMerchantNowParent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.groupParent {
  width: auto;
  padding: 16px;
  box-sizing: border-box;
  gap: var(--gap-xl);
}
.frameParent {
  position: absolute;
  top: 100px;
  left: 0;
  gap: var(--gap-21xl);
  font-size: var(--bold-size);
}
.lineIcon {
  align-self: stretch;
  position: relative;
  width: auto;
  overflow: hidden;
  height: 1px;
  flex-shrink: 0;
  object-fit: fill;
  background-color: #949494;
}
.button3,
.button4 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.button4 {
  border-radius: var(--br-11xs);
  padding: var(--padding-xs-5) 0;
}
.button3 {
  gap: var(--gap-5xs);
}
.copywrite2023 {
  position: relative;
  color: var(--gray-gray-500);
}
.footer,
.footer1 {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.footer1 {
  align-self: stretch;
  justify-content: center;
  padding: 0 0 var(--padding-base);
  gap: var(--gap-base);
}
.footer {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  justify-content: flex-start;
  padding: 0 var(--padding-base);
  box-sizing: border-box;
  gap: var(--gap-5xl);
  color: var(--primary-primary-2);
}
.landingPageMobile {
  position: relative;
  background-color: var(--gray-white);
  width: 100%;
  height: 1761px;
  overflow: hidden;
  text-align: left;
  font-size: var(--body-2-regular-size);
  color: var(--gray-black);
  font-family: var(--body-2-regular);
}
