@import url("https://fonts.googleapis.com/css2?family=Cerebri+Sans+Pro:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400&display=swap");
body {
  margin: 0;
  line-height: normal;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', 'Cerebri Sans Pro',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
:root {
  /* fonts */
  --body-3-bold: "Cerebri Sans Pro";
  --font-roboto: Roboto;
  --font-inherit: inherit;

  /* font sizes */
  --body-2-regular-size: 16px;
  --body-3-bold-size: 18px;
  --subheadline-bold-size: 28px;
  --font-size-xs: 12px;
  --body-regular-size: 14px;

  /* Colors */
  --gray-white: #fff;
  --color-whitesmoke-100: #f1f3f4;
  --color-whitesmoke-200: #e9e9e9;
  --primary-primary-2: #7b73f4;
  --color-darkslategray-100: #494c4f;
  --gray-gray-500: #3e3e3e;
  --gray-black: #000;
  --color-lavender: #dfdcff;
  --color-gainsboro-100: #dee1e6;
  --gray-gray-300: #d9d9d9;
  --color-dimgray: #696a6c;
  --gray-gray-400: #949494;

  /* Gaps */
  --gap-29xl: 48px;
  --gap-base: 16px;
  --gap-5xl: 24px;
  --gap-xl: 20px;
  --gap-xs: 12px;
  --gap-5xs: 8px;
  --gap-3xs: 10px;
  --gap-21xl: 40px;
  --gap-4xs: 9px;

  /* Paddings */
  --padding-xs-5: 11.5px;
  --padding-base: 16px;
  --padding-7xs: 6px;
  --padding-5xs: 8px;
  --padding-37xl: 56px;
  --padding-lgi: 19px;

  /* Border radiuses */
  --br-5xs: 8px;
  --br-7xs: 6px;
  --br-81xl: 100px;
  --br-11xs: 2px;
  --br-sm: 14px;
}
