
.modal{

  hr{
    //border: 1px solid #000;
    height: 0.5px;
    background-color: #151515;
  }
  .change_img{
    background-color: #000;
    margin: -30px 0 -30px -30px;
  }
  .bsn{
    padding: 15px;
  }
  .close-btn{
    height: 35px;
    width: 35px;
    float: right;
    cursor: pointer;
    img{
      width: 100%;
    }
    margin-right: 2px;
  }
  background-color: #E9E3DD;
  color: #000;
  :global(.modal-dialog){
    max-width: 100%;
    width: 100%;
    margin: 0;
    @media(min-width: 1201px){
      position: fixed;
      .content{

        overflow-y: scroll;
        height: 100vh;
      }
    }
  }
  :global(.modal-content){
    max-width: 100%;
    width: 100%;
    border-radius: unset;   
    border-color: #E9E3DD;
    background-color: #E9E3DD;
    height: 100%;
  }
  .jobs{
    .img{
      height: 870px;
      background-image: url("../../assets/bookingImg.png");
      background-repeat: no-repeat;
      background-size: cover;
      background-position: left;
    }
  }
  .logo{
    @media (min-width:767px) {
      display: none;
    }
    width: 175px;
    left: 0px;
    top: 0px;
    display: flex;
    vertical-align: middle;
    justify-content: flex-end;
    align-items: center;
    img {
      width: 100%;
    }
  }

  .creative-text{
    width: 100%;
    @media (min-width:902px) and (max-width: 1200px){
     margin-top: 100px
    }
    h3{
      font-style: normal;
      font-weight: 400;
      font-size: 43px;
      text-transform: uppercase;  
      padding-left: 50px;
      @media (min-width:902px) and (max-width: 1200px){
        font-size: 77px;
        //line-height: 85px;
      }
      @media (min-width:768px) and (max-width:901px){
        font-size: 60px;
        line-height: 60px;
        .imgWrapper{
          max-width: 300px !important;
          img{
            margin-top: -60px;
          }
        }
      }

      @media (max-width:430px){
        .creative{
          span{
            &:nth-child(1){
              margin-right: 0px;
            }
          }
        }
        div{
          &:nth-child(2){
            margin-top: -20px;
          }
        }
      }
      @media (max-width:767px){
        margin-top: 20px;
        font-size: 35px;
        line-height: 36px;
        .imgWrapper{
          max-width: 200px !important;
        }
        .imgtext{
          flex-direction: column !important;
          justify-content: flex-end;
          margin-top: 10px !important;
          div {
            position: relative !important;
            top: -56px;
            left: 187px;
          }
          .creative{
            position: relative;
            left: 0;
            top: -70px;
            span{
              margin-right: 0 !important;
            }
          }

        }
        img{
          margin-top: -28px !important;
          width: 128% !important;;
        }

        div{
          &:nth-child(2){
            margin-top: -20px;
          }
        }
      }
    }

    .gray-h3 {
      opacity: .5;
      font-size: 34px;
      width: 38rem;
      @media (max-width:767px){
        font-size: 26px;
        width: 100%;
      }
    }

    .black-h3 {
      margin-top: 18rem;
      width: 40rem;
      @media (max-width:767px){
        font-size: 33px;
        width: 100%;
        margin-top: 9rem;
      }
      @media (min-width:902px) and (max-width: 1200px){
        width: 100%;
      }
    }

    .with-highlight{
      .imgWrapper{
        display: block;
        max-width: 600px;
        position: relative;
      }
      img{
        width: 55%;
        margin-top: -68px;
      }
      .imgtext{
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        div {
          position: static;
        }
        .creative{
          span{
            &:nth-child(1){
              margin-right: 20px;
            }
          }
        } 
      }
    }
  }
}

@media (max-width:1200px){
  .change_img{
    display: none;
  }
}
@media (min-width:1201px){
  .change_img{
    .jobs{
      .img{
        margin-right: -15px;
      }
    }
  }
}
