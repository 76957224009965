.main{
  padding: 103px;
  .hidelg{
    display: none;
  }
  .title{
    h1{
      font-style: normal;
      font-weight: 500;
      font-size: 123px;
      line-height: 140px;
      max-width: 1678px;
      
      /* or 80% */

      text-transform: uppercase;
    }
  }
  .top-image{
    //height: 416px;
    float: right;
    width: 100%;
    img{
      width: 100%;
    }
    @media (min-width:1403px){
      
     width: 90%;
      margin-top: -170px;
    }
  }
  .creative-text{
    width: 100%;
    text-align: right;
    h1{
      font-style: normal;
      font-weight: 500;
      font-size: 123px;
      line-height: 100px;
      text-transform: uppercase;  
      @media (min-width:902px) and (max-width: 1200px){
        font-size: 85px;
        line-height: 85px;
      }
      @media (min-width:768px) and (max-width:901px){
        font-size: 60px;
        line-height: 60px;
        .imgWrapper{
          max-width: 300px !important;
          img{
            margin-top: -60px;
          }
        }
      }

      @media (max-width:430px){
        .creative{
          span{
            &:nth-child(1){
              margin-right: 0px;
            }
          }
        }
        div{
          &:nth-child(2){
            margin-top: -20px;
          }
        }
      }
      @media (max-width:767px){
        font-size: 43px;
        line-height: 36px;
        .imgWrapper{
          max-width: 200px !important;
        }
        .imgtext{
          flex-direction: column !important;
          justify-content: flex-end;
          align-items: flex-end !important;
          margin-top: 10px !important;
          .creative{
            span{
              margin-right: 0 !important;
            }
          }

        }
        img{
          margin-top: -28px !important;
        }

        div{
          &:nth-child(2){
            margin-top: -20px;
          }
        }
      }

    }
    .with-highlight{
      .imgWrapper{
        display: block;
        max-width: 600px;
        position: relative;
      }
      img{
        width: 100%;
        margin-top: -100px;
      }
      .imgtext{
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: flex-start;
        margin-top: 30px;
        .creative{
          span{
            &:nth-child(1){
              margin-right: 20px;
            }
          }
        }
        
      }
      .creative{
       // display: ;
      }
    }
  }
  // .principles-list{
  //   margin-top: 118px;
  // }
  .principles{
    margin-top: 161px;
    @media(max-width:767px){
      .integrity{
        display: block;
      }
    }
    @media(min-width:768px){
      .integrity{
        display: none;
      }
    }
    .principles-title{
      max-width: 493px;
      font-style: normal;
      font-weight: normal;
      font-size: 40px;
      line-height: 40px;
      /* or 100% */
      display: flex;
      align-items: center;
      text-transform: uppercase;
      color: #FFFFFF;
      opacity: 0.3;
    }
    h2{
      margin-top: 100px;
      font-style: normal;
      font-weight: normal;
      font-size: 200px;
      line-height: 126px;
      /* identical to box height, or 63% */
      
      text-transform: uppercase;

      
    }
    h5{
      font-style: normal;
      font-weight: normal;
      font-size: 60px;
      line-height: 80px;
      /* or 133% */
      
      align-items: center;
      text-transform: uppercase;
    }
    p{
      font-style: normal;
      font-weight: normal;
      font-size: 20px;
      line-height: 28px;
      max-width: 355px;
      /* or 140% */
      
      
      color: #FFFFFF;
      
      mix-blend-mode: normal;
      opacity: 0.62;
    }
    @media (max-width:767px){
      h2{
        margin-top: 32px;
        font-size: 40px;
        line-height: 50px;
      }
      h5{
        font-size: 24px;
        line-height: 23px;
      }
      p{
        font-size: 16px;
        line-height: 19px;
      }
    }
    .btwn-numbers{
      width: 100%;
      img{
        width: 100%;
      }
    }
    .sittingimg{
      width: 100%;
      margin-top: -100px;
      img{
        width: 100%;
      }
    }
    .sittingimgtop{
      display: none;
    }
    .blackwhiteimg{
      width: 100%;
      img{
        width: 100%;
      }
    }
    .bannerimg{
      width: 100%;
      img{
        width: 100%;
      }
    }
    .positiontitle{
      font-style: normal;
      font-weight: 500;
      font-size: 144px;
      line-height: 140px;
      /* identical to box height, or 80% */
      position: relative;
      text-transform: uppercase;
      max-width: 602px;
      .lets{
        width: 200px;
        position: absolute;
        right: 0;
        top: -79px;
        img{
          width: 100%;
        }
      }
    }
  }
}



@media (max-width: 767px) {



  .hidesmall{
    display: none;
  }
  .hidelg{
    display: block !important;
  }
  .bannerimg{
    display: none;
  }
  .positiontitle{
    font-size: 43px !important;
    line-height: 32px !important;

    max-width: 300px !important;
    .lets{
      width: 118px !important;
      top: -41px !important;
    }
  }
  
  .sittingimg{
    display: none;
  }

  .sittingimgtop{
    display: block !important;
    position: absolute;
    width: 122px;
    margin-top: -50px;
    right: -10px;

    img{
      width: 100%;

    }
  }
  .main{
    padding: 20px;
    padding-top: 103px;

    .title{
      h1{
        font-size: 43px;
        line-height: 36px;
      }
    }
    // .creative-text{
    //   h1{
    //     font-size: 43px;
    //     line-height: 36px;
    //   }
    //   .with-highlight{
    //     img{
    //       display: none;
    //     }
    //   }
    // }
  }
}

@media (min-width:992px){
  .sittingimg{
    float: right;
    margin-right: -103px;
    margin-bottom: 100px;
  }
  .blackwhiteimg{
    float: left;
    margin-left: -103px;
    margin-bottom: -350px;
    position: relative;
    z-index: 9;

  }
}
// @media(min-width:768px) and (max-width:1200px){
//   .hidesmall{
  
//     font-size: 100px !important;
//     line-height: 92px !important;
  
//   }
// }

@media(min-width:1201px){
  .positiontitle{
    float: right;
    // max-width: 380px !important;
    font-size: 120px !important;
    .lets{
      width: 150px !important;
      top: -70px !important;
    }
  }
}

@media (min-width:768px) and (max-width:1200px){
  .main{

    .title{
      h1{
        font-size: 90px !important;
        line-height: 100px !important;
      }
    }
    .creative-text{
      h1{
        margin-bottom: -30px;
      }
    }
  

    .principles{
      margin-top: 100px;
      h2{
        font-size: 100px;
      }
      h5{
        font-size: 40px !important;
      }
      p{
        max-width: 200px !important;
      }
    }
    .positiontitle{
      max-width: 380px !important;
      font-size: 80px !important;
      .lets{
        width: 150px !important;
        top: -44px !important;
      }
    }
  }
  
}