//css for number is in base.scss

.position-name{
  max-width: 1134px;
  font-style: normal;
  font-weight: 500;
  font-size: 100px;
  line-height: 75px;
  &:hover{
    color: #7557C4 !important;
    .num{
      color: #7557C4 !important;
      opacity: 1;
    }

  }
  position: relative;
  align-items: center;
  text-transform: uppercase;

  color: #FFFFFF;
  margin-top: 36px;
  cursor: pointer;
}


@media (max-width:767px){
  .position-name{
    font-weight: 500;
    font-size: 32px !important;
    line-height: 32px !important;
  }
}

