@import url('https://fonts.googleapis.com/css?family=Roboto:100,100i,300,300i,400,400i,500,500i,700,700i&display=swap');
@import "./fonts.scss";
@import "~bootstrap/scss/bootstrap";

@import "./color-functions.scss";

@import "./contrast-colors.scss";
@import "./contrast-colors.scss";
@import "./animated.scss";
html {
  background-color: theme-color("background", "dark");
}

html{
  overflow-x: hidden;
}
body {
  font-family: Founders Grotesk;
  background-color: #151515;
  font-style: normal;
  font-weight: normal;
  color: #ffffff;
}
@media (max-width:767px){
  body {
    overflow-x: hidden;
  }
}

@media screen and (max-width: 680px) {
  .show-on-desktop {
    display: none;
  }
}

@media screen and (min-width: 681px) {
  .hide-on-desktop {
    display: none;
  }
}

#root{
  height: 100%;
  max-width: 100%;
  width: 100%;
}

.logo{
  width: 175px;
  height: 68px;
  left: 0px;
  top: 0px;
  display: flex;
  vertical-align: middle;
  justify-content: flex-end;
  align-items: center;
  img {
    width: 100%;
  }
}

@media(max-width: 767px){
  .nav-brand{
    width: 95px !important;
    height: 36px !important;
    img{
      width: 100%;
    }
  }
}

.navbar-nav{
  a{
    margin-left: 107px;
    font-family: Avenir;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 31px;

    position: relative;
    .nav-num{
      display: none;
    }

    // &:after {    
    //   background: none repeat scroll 0 0 transparent;
    //   bottom: 0;
    //   content: "";
    //   display: block;
    //   height: 1px;
    //   left: 50%;
    //   position: absolute;
    //   background: #fff;
    //   transition: width 0.3s ease 0s, left 0.3s ease 0s;
    //   width: 0;
    // }
    // &:hover:after { 
    //   width: 100%; 
    //   left: 0; 
    // }



  }
}
.joinuslink{
  @media(min-width:992px){
    &:after {    
      background: none repeat scroll 0 0 transparent;
      bottom: 12px;
      content: "";
      display: block;
      height: 1px;
      left: 50%;
      position: absolute;
      //background: #fff;
      //transition: width 0.3s ease 0s, left 0.3s ease 0s;
      animation:underlineAnimation 0.8s ease 0s, left 0.8s , right 0.8s ease 0s;
      width: 100%;
      margin: auto;
      max-width: 59px;
      left: 0;
      right: 0;
    }
    &:hover {
      text-decoration: underline;
    }
  }

}

@keyframes underlineAnimation {
  from { width: 0; left: 50%; right: 50%}
  to   { width: 100%; left: 0; right: 0}
}
/* MAX-WIDTH 767 */
@media (max-width: 767px) {
  .logo {
    width: 175px;
    height: 68px;
    img {
      width: 100%;
    }
  }
}


@media (min-width:991px){
  .navbar{
    padding-top: 21px;
    padding-left: 60px;
    padding-right: 50px;
  }
}
@media (max-width:991px){
  .navbar-toggler{
    position: absolute;
    right: 16px;
    z-index: 5;
  }

  .navbar-collapse{
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: #000;
    margin: auto;
    z-index: 4;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    align-items: flex-start;
    padding: 18px;

    height: 100%!important;
    
    .navbar-nav {
      a{
        margin-left: 0;
        font-family: Founders Grotesk;
        font-style: normal;
        font-weight: 500;
        font-size: 43px;
        line-height: 40px;
        text-transform: uppercase;

        color: #FFFFFF !important;
        opacity: 7;
        margin-top: 43px;
        .nav-num{
          display: block !important;
          font-style: normal;
          font-weight: normal;
          font-size: 12px;
          line-height: 12px;
          float: left;
          margin-top: -20px;
        }
      }
    }

  }
}


.input-group {
  position: relative;
  margin: 40px 0 20px;
  .animated{
    width: 100%;
  }
  .input-border{
    width: 100%;
    width: 100%;
    height: 1px;
    border: none;
    border-bottom: solid 1px #757575 !important;
  }
}

input {
  font-size: 18px;
  padding: 10px 10px 10px 5px;
  display: block;
  width: 300px;
  border: none;
  //border-bottom: 1px solid #757575;
}

input:focus {
  outline: none;
}

label {
  color: #999;
  font-size: 18px;
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  left: 5px;
  top: 10px;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
}

input:focus ~ label,
input:valid ~ label {
  top: -20px;
  font-size: 14px;
  color: #B54DC8;
}



// :global(.navbar-toggler .collapsed){
//   .main-menu-icon{
//     background-image: url("../assets/menu_close_icon.png");
//   }
// }


.main-menu-icon{
  width: 24px;
  height: 18px;
  background-image: url("../assets/menu.png");
  background-repeat: no-repeat;
  background-position: center;
}

.main-menu-icon-close{
  width: 24px;
  height: 18px;
  background-image: url("../assets/menu_close_icon.png");
  background-repeat: no-repeat;
  background-position: center;
}
.bar {
  position: relative;
  display:block;
  width:315px;
}

.bar:before,
.bar:after {
  content: '';
  height: 2px;
  width: 0;
  bottom: 1px;
  position: absolute;
  background: #4285f4;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
}

.bar:before {
  left: 50%;
}

.bar:after {
  right: 50%;
}

input:focus ~ .bar:before,
input:focus ~ .bar:after {
  width: 50%;
}

.highlight {
  position: absolute;
  height: 60%;
  width: 100px;
  top: 25%;
  left: 0;
  pointer-events: none;
  opacity: 0.5;
}

input:focus ~ .highlight {
  -webkit-animation: inputHighlighter 0.3s ease;
  -moz-animation: inputHighlighter 0.3s ease;
  animation: inputHighlighter 0.3s ease;
}

/* animations */
@-webkit-keyframes inputHighlighter {
  from { background: #4285f4; }
  to   { width: 0; background: transparent; }
}
@-moz-keyframes inputHighlighter {
  from { background: #4285f4; }
  to   { width: 0; background: transparent; }
}
@keyframes inputHighlighter {
  from { background: #4285f4; }
  to   { width: 0; background: transparent; }
}



.position-num1, .position-num3 {
  top: -13px;
  float: right;
  left: 28.5rem;
  position: absolute;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  text-transform: uppercase;
  opacity: 0.5;
  &:hover{
    color: #7557C4;
  }
  span {
    color: #FFFFFF !important;
  }
}

.position-num2 {
  top: -13px;
  float: right;
  left: 35.5rem;
  position: absolute;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  text-transform: uppercase;
  opacity: 0.5;
  &:hover{
    color: #7557C4;
  }
  span {
    color: #FFFFFF !important;
  }
}


@media (max-width:767px){
  .position-num1, .position-num2, .position-num3{
    display: none;
  }
}
