
.modal{

  hr{
    //border: 1px solid #000;
    height: 0.5px;
    background-color: #151515;
  }
  .change_img{
    background-color: #000;
    margin: -30px 0 -30px -30px;
  }
  .bsn{
    padding: 15px;
    max-height: 500px;
    padding-bottom: 300px;
  }
  .close-btn{
    height: 35px;
    width: 35px;
    float: right;
    cursor: pointer;
    img{
      width: 100%;
    }
    margin-right: 2px;
  }
  background-color: #E9E3DD;
  color: #000;
  :global(.modal-dialog){
    max-width: 100%;
    width: 100%;
    margin: 0;
    @media(min-width: 1201px){
      position: fixed;
      .content{

        overflow-y: scroll;
        height: 100vh;
      }
    }
  }
  :global(.modal-content){
    max-width: 100%;
    width: 100%;
    border-radius: unset;   
    background-color: #E9E3DD;
    height: 100%;
  }
  .jobs{
    .img{
      height: 500px;
      background-image: url("../../../../assets/Couple.png");
      background-repeat: no-repeat;
      background-size: cover;
      background-position: left;
    }
  }

  .content{
    background-color: #E9E3DD;
    padding: 20px;
    .title{
      font-style: normal;
      font-weight: normal;
      font-size: 60px;
      line-height: 55px;
      /* or 183% */
  
      align-items: center;
      text-transform: uppercase;

  
    }
    .f-para, .f-para2{
      font-style: normal;
      font-weight: normal;
      font-size: 20px;
      line-height: 28px;
      text-transform: uppercase;
      color: #000;
    }
    .f-para2{
      color: #989490;
      margin-bottom: 70px;
    }
    p{
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 21px;
      /* or 117% */

      display: flex;
      align-items: center;

      color: #989490;
    }
    .col-div{
      column-count: 2;
      margin-bottom: 20px;
    }
    .col-div1{
      column-count: 1;
      margin-bottom: 20px;
    }
    .send-btn{
      margin-top: 65px;
      img{
        width: 60.24px;    
        margin-top: -19px;
        margin-right: 15px;
      }
      button{
        font-style: normal;
        font-weight: 500;
        font-size: 60px;
        line-height: 80px;
        /* identical to box height, or 133% */

        display: flex;
        align-items: center;
        text-transform: uppercase;

        color: #151515;
        background-color: transparent;
        border: none;
        height: 70px;
      }
      a {
        color: #151515;
      }
      a:hover {
        text-decoration: none;
        color: #151515;
      }
    }
  }
}

@media (max-width:1200px){
  .change_img{
    display: none;
  }
}
@media (min-width:1201px){
  .change_img{
    .jobs{
      .img{
        margin-right: -15px;
      }
    }
  }
}
@media (max-width:767px){
  .col-div{
    column-count: 1 !important;
  }
  .content{
    background-color: #E9E3DD;
    padding: 20px;
    .title{
      font-size: 43px !important;
      line-height: 32px !important;
  
    }
    .f-para, .f-para2{
      font-size: 20px !important;
      line-height: 28px !important;
    }
  }
  .send-btn{
    button{
      font-style: normal;
      font-weight: 500;
      font-size: 32px !important;
      line-height: 24px !important;
      a:hover {
        text-decoration: none;
      }
    }
    img{
      margin: 0;
      width: 28.37px !important;
    }
   
  }
}