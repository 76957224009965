
.banner-img{
  width: 100%;
  img{
    width: 100%;
  }
}
.cluster{
  margin-top: 117px;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: wrap;
}
.my-gallery-class{
  height: 500px;
  padding-top: 100px;
}
.banner-title{
  font-style: normal;
  font-weight: normal;
  font-size: 100px;
  line-height: 100px;
  /* identical to box height, or 160% */
  
  text-transform: uppercase;
}
.text-element{

  cursor: pointer;
  padding: 15px;
  &:hover{
    text-decoration: none;
  }
  .txt{
    font-family: Founders Grotesk;
    font-style: normal;
    font-weight: 500;
    font-size: 122px;
    line-height: 85px;
    /* identical to box height, or 62% */
    
    text-transform: uppercase;
    
    color: #ffffff; 
    white-space: nowrap;
    &:hover{
      color: #7557C4;
      // .num{
      //   color: #7557C4 !important;
      //   opacity: 1;
      // }
    }
  }
  .num{
    @media(min-width: 767px){
      margin-top: -20px;
    }
    position: absolute;
    margin-left: 10px;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 21px;
    /* identical to box height, or 117% */
    text-transform: uppercase;
    a {
      color: #FFFFFF !important;
    }
    opacity: 0.5;
    &:hover{
      color: #7557C4;
    }
  }

  .imgan{
    z-index: 9;
    position:absolute;
    top: 0;
  }
  .image{
    width: 500px;
    z-index: 9;
    img{
      width: 100%;
      height: 100%;
    }
  }
}

@media (min-width:768px) and (max-width:1200px){
  .text-element{
    .txt{
      font-size: 100px;
      white-space:normal;
    }
  }
}

@media (max-width: 767px) {
  .text-element{
    .txt{
      font-size: 36px;
    }
    .image{
      display: none;
    }
  }
  .banner-title{
    font-size: 60px;
    line-height: 45px;
  }
  .cluster{
    flex-direction: column;
    span{
      line-height: 0 !important;
    }
  }
}